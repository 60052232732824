<template>
  <div>
    <load-profile></load-profile>
    <!-- Table Container Card -->
    <b-card>
      <validation-observer ref="createForm" #default="{ invalid }">
        <!-- form -->
        <n-form-confirmation
          key="createForm"
          @submit="submit"
          :form="$refs.createForm"
          :disabled="invalid"
        >
          <n-input :fields="fields" v-model="data" :initValue="initData">
            <template #fightMatch>
              <fight-match-form v-model="data.fightMatch" />
            </template>
          </n-input>
          <b-row>
            <b-col cols="12" class="text-right">
              <n-button-loading
                type="submit"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-1 mr-1"
                :loading="loading"
                v-if="$can('create', 'baccarat-result')"
              >
                {{ $t("button.saveChanges") }}
              </n-button-loading>
              <n-button-loading
                type="button"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                :loading="loading"
                class="mt-1"
                @submit="back"
              >
                {{ $t("button.back") }}
              </n-button-loading>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BFormRadioGroup,
  BSpinner,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import Ripple from "vue-ripple-directive";
import NButtonLoading from "@/components/NButtonLoading";
import NSingleSelect from "@/components/NSingleSelect";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInput from "@/components/NInput";
import FormInput from "./formInput";
import Alphabet from "@/data/alphabet.js";
import FightMatchForm from "./Form.vue";

const BaccaratResultRepository = Repository.get("baccaratResult");

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BFormRadioGroup,
    NButtonLoading,
    NFormConfirmation,
    NInput,
    BSpinner,
    NSingleSelect,
    FightMatchForm,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        no: null,
        fightMatch: [
          {
            no: 1,
            result: null,
            subResult: null,
          },
        ],
      },
      initData: {},
      loading: false,
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.show();
    }
  },
  methods: {
    show() {
      BaccaratResultRepository.show(this.$route.query.id).then((response) => {
        delete response.data.data.username;

        this.initData = {
          ...response.data.data,
        };
      });
    },
    submit() {
      this.$refs.createForm.validate().then((success) => {
        if (success) {
          this.loading = true;
          BaccaratResultRepository.create(this.data)
            .then((response) => {
              this.$router.push({
                name: "view-baccarat-result",
                params: { id: response.data.data.id },
              });
            })
            .catch((error) => {
              if (error.response?.status == 422) {
                this.$refs.createForm.setErrors(error.response?.data?.errors);
              }
            })
            .then(() => {
              this.loading = false;
            });
        }
      });
    },
    back() {
      this.$router.back();
    },
  },
  setup() {
    const fields = [...FormInput];

    const alphabet = [...Alphabet];
    return { fields, alphabet };
  },
};
</script>
